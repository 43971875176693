<template>

	<mdb-container class="mt-5">

		<mdb-card>
			<mdb-card-body class="card-body-padding text-center">

				<p class="mb-4">
					<icon name="clock" type="tffi-icon" class="text-primary huge-tffi-icon">
						<span class="path1"></span>
						<span class="path2"></span>
						<span class="path3"></span>
						<span class="path4"></span>
						<span class="path5"></span>
						<span class="path6"></span>
						<span class="path7"></span>
						<span class="path8"></span>
						<span class="path9"></span>
						<span class="path10"></span>
						<span class="path11"></span>
						<span class="path12"></span>
						<span class="path13"></span>
						<span class="path14"></span>
					</icon>
				</p>

				<h3 class="font-weight-bold uppercase text-info mt-4">{{ $t('newApplication.submittingTitle') }}<span class="ellipsis"></span></h3>

				<p class="max-p my-4">{{ $t('newApplication.submittingText') }}</p>

			</mdb-card-body>
		</mdb-card>

	</mdb-container>

</template>

<script>

	import { mapActions, mapState } from 'vuex';
	import BaseMixin from './BaseMixin';
	import { getStatus, cancelRequests, isCancel } from '../../../api';
	import moment from 'moment';

	export default {

		mixins: [
			BaseMixin
		],

		data() {

			return {
				submitDt: null,
				refreshTimeout: null,
				cancelToken: {}
			};

		},

		computed: {

			...mapState({
				applicationStatus: state => state.application.info.data.status
			})

		},

		watch: {

			'applicationStatus'(status) {

				if (status) {

					this.redirectBasedOnStatus(status);

				}

			}

		},

		mounted() {

			console.log('formSubmitting', localStorage.getItem('formSubmitting'));
			
			if (localStorage.getItem('formSubmitting')) {
				this.refreshStatusLookupLoop()
				return;
			} else {

			}

			this.submitApplicationFunction();

		},

		beforeRouteLeave(to, from, next) {

			// cancelRequests();

			this.clearTimeout();

			next();

		},

		methods: {

			...mapActions([
				'throwFatalError',
				'submitApplicationAction',
				'saveApplicationAction',
				'saveValidateAll'
			]),

			async submitApplicationFunction() {

				if (this.refreshTimeout || this.submitDt) {

					return;

				}

				let stop = false;
				this.submitDt = moment();

				localStorage.setItem('formSubmitting', 'true');

				let status = await this.submitApplicationAction(this.$route.params.applicationId).catch(error => {

					let errorMessages = error.response && error.response.data && error.response.data.errorMessages;

					if (errorMessages) {

						console.debug('submit application errors', errorMessages);
						this.handleErrors(errorMessages);
						stop = true;

					}

					let errorMessage = error.message || error.response && error.response.data && error.response.data.errorMessage;

					if (errorMessage && errorMessage !== 'Network Error' && (!errorMessages || !Object.keys(errorMessages))) {

						this.throwFatalError(errorMessage);

					}

					console.debug('submit done with error', errorMessage, error);

				});

				localStorage.removeItem('formSubmitting')

				if (!stop && (!status || !this.redirectBasedOnStatus(status))) {

					this.refreshStatusLookupLoop();

				}

			},

			async refreshStatusLookupLoop() {

				if (this.refreshTimeout) {

					console.log('returning');
					

					return;

				}

				let stop = false;

				let result = await getStatus(this.$route.params.applicationId, this.cancelToken).catch(error => {

					if (isCancel(error)) {

						stop = true;

					} else {

						console.debug('refreshStatusLookupLoop error', error);

					}

				});

				if (result && result.status) {


					if (this.redirectBasedOnStatus(result.status)) {

						stop = true;

					}

				}

				if (stop) {

					this.clearTimeout();
					return;

				}

				let now = moment();
				let timeAfterSubmit = moment(this.submitDt).add(20, 'seconds');

				if (now.isSameOrAfter(timeAfterSubmit)) {

					this.clearTimeout();

					return this.$router.push({
						name: 'Dashboard'
					});

				}

				this.refreshTimeout = setTimeout(() => {

					this.clearTimeout();

					this.refreshStatusLookupLoop();

				}, 2000);

			},

			clearTimeout() {

				clearTimeout(this.refreshTimeout);
				this.refreshTimeout = null;

			},

			handleErrors(errors) {

				let ApplicationErrors = errors.application;
				let CoApplicationErrors = errors.coApplicant;

				this.saveValidateAll(true);

				this.showErrorAlert({
					message: this.$i18n.t('fatalError.errorAppForm'),
					timeout: null
				});

				if (Object.keys(ApplicationErrors).length) {

					return this.$router.push({
						name: 'ApplicationStep4',
						params: {
							applicationId: this.applicationId
						}
					});

				} else if (Object.keys(CoApplicationErrors).length) {

					return this.$router.push({
						name: 'CoBorrowerStep3',
						params: {
							applicationId: this.applicationId
						}
					});

				}

			},

			redirectBasedOnStatus(status) {

				if (!status) {

					this.throwFatalError(`Internal Server Error`);

				}

				localStorage.removeItem('formSubmitting')

				return this.$router.push({
					name: 'Dashboard'
				});

				switch (status) {

					case 'Approved Conditional':
						return this.$router.push({
							name: 'Approved Conditional',
							params: {
								applicationId: this.applicationId
							}
						});

					case 'Declined':
					case 'NotApproved':
					case 'Not Approved':
						return this.$router.push({
							name: 'NotApproved',
							params: {
								applicationId: this.applicationId
							}
						});

					case 'Bureau Not Found':
					case 'BureauNotFound':
						return this.$router.push({
							name: 'CreditBureauNotFound',
							params: {
								applicationId: this.applicationId
							}
						});

				}

			}

		}

	};

</script>

<style lang="scss" scoped>

	.max-p {
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
	}

	.ellipsis {
		display: inline-block;
		width: 1em;
		text-align: left;

		&:after {
			overflow: hidden;
			display: inline-block;
			vertical-align: bottom;
			animation: ellipsis steps(4, end) 1.2s infinite;
			content: "\2026"; /* ascii code for the ellipsis character */
			width: 0px;
		}

	}

	@keyframes ellipsis {
		to {
			width: 1.05em;
		}
	}

	@-webkit-keyframes ellipsis {
		to {
			width: 1.05em;
		}
	}

</style>
